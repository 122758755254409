import model from './model';
import { components } from './components';
// import wixWidget from 'wix-widget'
export default model.createController(({ $bind, $widget, $w, flowAPI }) => {
  const wixWidget: any = flowAPI.controllerConfig.wixCodeApi.widget;
  const setImage = (imageUrl: string) => {
    const imageLink = `https://static.wixstatic.com/media/${imageUrl}`;
    console.log('imagebg | imageUrl:', imageLink);
    $w(components.image).src = imageLink;
  };
  // Setting design doesn't work due to WBL-6106 | globalDesignPresetChanged not fired when changing preset
  $widget.onPropsChanged((oldProp, newProp) => {
    if (
      newProp.imageShape.toString().includes('square') &&
      (newProp.layout.toString().includes('card') ||
        newProp.layout.toString().includes('overlap'))
    ) {
      console.log('Changing state to: cardAndOverlap_square');
      // wixWidget.setDesignPreset('cardAndOverlap_square');
    } else if (
      newProp.imageShape === 'square' &&
      newProp.layout === 'sideBySide'
    ) {
      console.log('Changing state to: sideBySide_square');
      // wixWidget.setDesignPreset('sideBySide_square');
    } else if (
      newProp.imageShape === 'circle' &&
      (newProp.layout === 'card' || newProp.layout === 'overlap')
    ) {
      console.log('Changing state to: cardAndOverlap_circle');
      // wixWidget.setDesignPreset('cardAndOverlap_circle');
    } else {
      console.log('Changing state to: sideBySide_circle');
      // wixWidget.setDesignPreset('sideBySide_circle');
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setImage,
      // collapse
    },
  };
});
