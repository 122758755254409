import model from './model';
import { components } from './components';

export default model.createController((controllerParams) => {
  const { $bind, $widget, flowAPI, $w, controllerConfig } = controllerParams;
  const t = flowAPI.translations.t;
  
  const setTranslation = (string) => {

    // @ts-ignore
    $w(components.badge).text = string

  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setTranslation
    },
  };
});
