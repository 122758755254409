
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller0 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/Widget1/viewer.controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/Widget1/model.ts';
            var controllerExport0 = _controllerExport0;
            

            
import wrapController1 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller1 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/all presets/viewer.controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/all presets/model.ts';
            var controllerExport1 = _controllerExport1;
            

            
import wrapController2 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller2 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/badge/viewer.controller.ts';
            import * as _controllerExport2 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/badge/model.ts';
            var controllerExport2 = _controllerExport2;
            

            
import wrapController3 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller3 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/image multi not to use/viewer.controller.ts';
            import * as _controllerExport3 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/image multi not to use/model.ts';
            var controllerExport3 = _controllerExport3;
            

            
import wrapController4 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller4 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/imagebg/viewer.controller.ts';
            import * as _controllerExport4 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/imagebg/model.ts';
            var controllerExport4 = _controllerExport4;
            

            
import wrapController5 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller5 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/inner repeater/viewer.controller.ts';
            import * as _controllerExport5 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/inner repeater/model.ts';
            var controllerExport5 = _controllerExport5;
            

            
import wrapController6 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller6 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/main layouter/viewer.controller.ts';
            import * as _controllerExport6 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/main layouter/model.ts';
            var controllerExport6 = _controllerExport6;
            

            
import wrapController7 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller7 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/main repeater/viewer.controller.ts';
            import * as _controllerExport7 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/main repeater/model.ts';
            var controllerExport7 = _controllerExport7;
            

            
import wrapController8 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller8 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/presets main/viewer.controller.ts';
            import * as _controllerExport8 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/presets main/model.ts';
            var controllerExport8 = _controllerExport8;
            

            
import wrapController9 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller9 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/slots layouter/viewer.controller.ts';
            import * as _controllerExport9 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/slots layouter/model.ts';
            var controllerExport9 = _controllerExport9;
            

            
import wrapController10 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller10 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/test/viewer.controller.ts';
            import * as _controllerExport10 from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/test/model.ts';
            var controllerExport10 = _controllerExport10;
            

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    import { blocksControllerService } from '@wix/yoshi-flow-editor/runtime/esm/blocks/controllerService'
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = null;

  var experimentsConfig = {"centralized":true,"scopes":[]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var defaultTranslations = {"app.widget.noServices":"Nothing to book right now. Check back soon","app.widget.displayName":"Next availability","app.widget.nickname":"Next availability widget","app.widget.availableOnline":"Available online","app.widget.bookNextSession":"Book your next session","app.widget.seeMoreDatesAndTimes":"See more dates and times","app.widget.noServiceAvailability":"This service has no availability in the next 6 months. Please contact us or check out our other services.","app.elements.title":"Choose what to display","app.elements.image":"Image","app.elements.conference":"Video conferencing badge","app.elements.serviceTitle":"Service name","app.elements.durationAndPrice":"Duration and price","app.elements.divider":"Divider","app.elements.timezone":"Time zone","app.elements.bookYourNextSession":"Book your next session","app.elements.seeMoreDatesAndTimes":"See more dates and times","app.settings.headline":"Settings","app.settings.addFirstServiceButton":"Add Your First Service","app.settings.createService":"Create a service to show it’s upcoming availability.","app.settings.chooseService":"Choose a service to display","app.settings.addNewService":"Add New Service","app.settings.chooseServiceLocation":"Choose a service location","app.layout.headline":"Layout","app.layout.subtitle":"Choose what to display","app.layout.widgetLayoutChooseLayout":"Choose a layout","app.layout.widgetLayoutTimeSlotToDisplay":"Displayed time slots","app.layout.card":"Card","app.layout.overlapping":"Overlapping","app.layout.side":"Side by side","app.layout.oneSlot":"1 time slot","app.layout.twoSlots":"2 time slots","app.layout.threeSlots":"3 time slots","app.gfpp.manageServices":"Manage Services","app.gfpp.settings":"Settings","app.design.title":"Design","app.design.badge.mainAction":"Change Design","app.design.section.title":"Design image","app.design.badge.tab":"Design badge","app.design.background":"Background"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "next-available-widget",
    biConfig: null,
    appName: "New Yoshi Blocks App",
    appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/Widget1/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "Widget1",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-oqtrb" }, { method: controller1,
          wrap: wrapController1,
          exports: controllerExport1,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/all presets/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "all presets",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-m81ua" }, { method: controller2,
          wrap: wrapController2,
          exports: controllerExport2,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/badge/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "badge",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-m1qie" }, { method: controller3,
          wrap: wrapController3,
          exports: controllerExport3,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/image multi not to use/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "image multi not to use",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-tabra" }, { method: controller4,
          wrap: wrapController4,
          exports: controllerExport4,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/imagebg/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "imagebg",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-jv8vy" }, { method: controller5,
          wrap: wrapController5,
          exports: controllerExport5,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/inner repeater/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "inner repeater",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-s34zt" }, { method: controller6,
          wrap: wrapController6,
          exports: controllerExport6,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/main layouter/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "main layouter",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-l8mc1" }, { method: controller7,
          wrap: wrapController7,
          exports: controllerExport7,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/main repeater/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "main repeater",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-lcchp" }, { method: controller8,
          wrap: wrapController8,
          exports: controllerExport8,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/presets main/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "presets main",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-i7n7k" }, { method: controller9,
          wrap: wrapController9,
          exports: controllerExport9,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/slots layouter/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "slots layouter",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-yeq1v" }, { method: controller10,
          wrap: wrapController10,
          exports: controllerExport10,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/test/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "d4540e90-fae1-44bf-922f-17c65b413c9f",
          projectName: "next-available-widget",
          componentName: "test",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d4540e90-fae1-44bf-922f-17c65b413c9f-eavar" }],
    false);

    export const createControllers = _createControllers
