import model from './model';
import { components } from './components';

let businessLocale;
export default model.createController(({ $bind, $widget, $w, flowAPI }) => {
  const setRepeaterData = (array, businessLocaleData) => {
    // console.log('Got main repeater data:', array);
    $w(components.mainRepeater).data = [];
    $w(components.mainRepeater).data = array;
    businessLocale = businessLocaleData;
  };

  const createInnerRepeaterArray = (slots, businessLocaleData) => {
    // console.log('Main repeater | createInnerRepeaterArray data:', slots);
    const mapOfSlots = new Map();
    const innerRepeaterData: any[] = [];
    let arrayOfKeys: string[] = [];

    for (const slot of slots) {
      if (arrayOfKeys.includes(slot.startDate)) {
        mapOfSlots.get(slot.startDate).push(slot);
        arrayOfKeys = Array.from(mapOfSlots.keys());
      } else {
        mapOfSlots.set(slot.startDate, [slot]);
        arrayOfKeys = Array.from(mapOfSlots.keys());
      }
    }
    mapOfSlots.forEach((value, key) => {
      innerRepeaterData.push({
        _id: innerRepeaterData.length.toString(),
        date: new Date(key)
          .toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone:
              businessLocaleData.preferredLocale === 'business'
                ? businessLocaleData.businessLocale
                : businessLocaleData.userLocale,
          })
          .toLowerCase(),
        slots: value,
      });
    });

    // console.log('returning innerRepeaterData:', innerRepeaterData);
    return innerRepeaterData;
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      $w(components.mainRepeater).onItemReady(async ($item, data, index) => {
        // console.log('Main repeater data:', data);
        $item(components.dayText).text = data.date;
        $item(components.dayText).expand();
        // console.log('Setting inner repeater data', data.slots);
        $item(components.slotRepeater).setRepeaterData(
          createInnerRepeaterArray(data.slots, businessLocale),
        );

        $item(components.slotRepeater).onButtonClicked(() => {
          // console.log('main repeater | caught onButtonClicked');
          $widget.fireEvent('buttonClicked', {});
        });
      });
    },
    exports: {
      setRepeaterData,
    },
  };
});
